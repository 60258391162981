
<!-- contenido -->
<div *ngIf="!isLogged">
  <router-outlet></router-outlet>
  <div class="component-content-logo"></div>
</div>


<div class="main_container" *ngIf="isLogged">
  <!-- header -->
  <app-header class="header"></app-header>

  <div class="main_content">
    <!-- menu lateral -->
    <app-menu class="menu" *ngIf="isLogged"></app-menu>
    <div class="component_container" #scrollContainer>
      <router-outlet></router-outlet>
    </div>
    <!-- <div class="component-content-logo"></div> -->
  </div>
</div>
