<div class="header">
    <div class="header__demo" *ngIf="isDemo">
        DEMO -
    </div>


    <app-filter></app-filter>
    <div class="header__icon cursor-pointer" (click)="goToChat()" [ngClass]="{'header__icon--selected' : activeMenuItem == 'chat'}">
        <img src="../../../../assets/icons/menu/chat.svg">
    </div>
    <div class="header__icon cursor-pointer" (click)="goToConfiguration()" [ngClass]="{'header__icon--selected' : activeMenuItem == 'configuration'}">
        <img src="../../../../assets/icons/menu/settings.svg">
    </div>
    <div class="header__icon cursor-pointer" *ngIf="false">
        <img src="../../../../assets/icons/menu/help-center.svg">
    </div>
    <div class="header__icon cursor-pointer" (click)="logout()">
        <img src="../../../../assets/icons/menu/share.svg">
    </div>

</div>