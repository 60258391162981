<!--<div class="navigation">
    <div class="navigation__item">
        <div class="navigation__item__back cursor-pointer">
            <img src="../../../../../../assets/icons/arrow-left-back.svg" (click)="back()" />
        </div>
        <span class="navigation__item__route" 
        [translate]="breadcrumb" 
        [translateParams]="{ param: param }"></span>
  

    </div>
</div> -->


<div [ngClass]="{ 'navigation': !isDashboard, 'navigation--dashboard': isDashboard }">
    <div *ngIf="isDashboard; else defaultNavigation" class="navigation__last">
        <span class="navigation__last__dashboard">Hi!</span>
        <img src="assets/icons/so-so.svg"/>
    </div>

    <ng-template #defaultNavigation>
        <div *ngIf="showBackButton()" class="navigation__last" (click)="back()">
            <img src="assets/icons/arrow-narrow-left.svg"/>
            <span class="navigation__last__text" [translate]="breadcrumbBack" [translateParams]="backParams"></span>
        </div>
    </ng-template>

    <span 
        *ngIf="showExtraBreadcrumb" 
        class="navigation__title--small" 
        [translate]="extraBreadcrumb">
    </span>
    <span 
        class="navigation__title" 
        [translate]="breadcrumb" 
        [translateParams]="params">
    </span>
    <span class="navigation__separator"></span>
</div>