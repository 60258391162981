import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';

import { Router } from '@angular/router';
import { NavigationService } from '@/app/shared/services/navigation/navigation.service';

@Component({
  selector: 'app-header-page',
  templateUrl: './header-page.component.html',
  styleUrls: ['./header-page.component.scss'],
  providers: [TitleCasePipe]
})
export class HeaderPageComponent implements OnInit {
  breadcrumb: string = '';
  extraBreadcrumb: string = 'app.breadcrumbs.teamExtra';
  entityName: string | undefined;
  user: string | undefined;
  breadcrumbBack: string = '';
  backParams?: { [key: string]: string };
  @Input() params?: { [key: string]: any };
  isDashboard: boolean = false;

  // Definir `previousUrl` y `currentUrl` como propiedades del componente
  previousUrl: string = '';
  currentUrl: string = '';

  showExtraBreadcrumb: boolean = false;

  constructor(
    private navigationService: NavigationService, 
    private titleCasePipe: TitleCasePipe,
    private router: Router,
    private location: Location,
  ) {
    
  }

  ngOnInit(): void {
    // Asignar valores a `previousUrl` y `currentUrl` en el momento de inicialización
    this.previousUrl = this.navigationService.previousUrl;
    this.currentUrl = this.navigationService.currentUrl;
    
    //this.user = this.params?.player || '';
    this.updateNavigationValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'] && changes['params'].currentValue) {
      this.capitalizeParams();
      this.user = this.params?.player || '';  
      this.updateNavigationValues();
    }
  }

  private updateNavigationValues(): void {
    this.isDashboard = this.currentUrl === '/dashboard';
    this.setBreadcrumb();
  }

  setBreadcrumb(): void {
    const routeMap: any = {
        '/dashboard': 'app.breadcrumbs.dashboard',
        '/players': 'app.breadcrumbs.team',
        '/stats': 'app.breadcrumbs.teamStats',
        '/team/player': 'app.breadcrumbs.teamPlayer',
        '/competitions': 'app.breadcrumbs.competitions',
        '/practices': 'app.breadcrumbs.practices',
        '/trips': 'app.breadcrumbs.trips',
        '/configurations': 'app.breadcrumbs.configuration',
        '/competitions/create': 'app.breadcrumbs.createCompetition',
        '/practices/sessions': 'app.breadcrumbs.practices',
        '/practices/sessions/create': 'app.breadcrumbs.practicesNewSession',
        '/practices/exercises': 'app.breadcrumbs.practicesExercises',
    };

    this.setExtraBreadcrumb()

    if (routeMap[this.currentUrl]) {
        this.breadcrumb = routeMap[this.currentUrl];

    } else if (/\/players\/\d+/.test(this.currentUrl)) { // Ruta dinámica: player + id
        this.breadcrumb = 'app.breadcrumbs.playerProfile';
        this.breadcrumbBack = 'app.breadcrumbs.teamPlayerBack';

    } else if (/\/stats\/\d+\/stat\/\d+/.test(this.currentUrl)) { // Ruta dinámica: stats + id + stat +id
        this.breadcrumb = 'app.breadcrumbs.teamPlayersPlayerStat';
        this.breadcrumbBack = 'app.breadcrumbs.teamPlayersPlayerStatBack';
        this.backParams = { player: this.user || '' };

    } else if (/\/stats\/\d+/.test(this.currentUrl)) { // Ruta dinámica: stats + id
        const initialUrl = this.navigationService.getInitialUrl();
        console.log('ruta inicial', initialUrl);
        
        this.breadcrumb = 'app.breadcrumbs.playerStatsCompetition';
        this.breadcrumbBack = initialUrl === '/players'
        ? 'app.breadcrumbs.playerProfileBack'
        : 'app.breadcrumbs.alternatePlayerProfileBack';
        
        this.backParams = { player: this.user || '' };
        

    } else if (/\/competitions\/create/.test(this.currentUrl)) { // Ruta dinámica competitions/create
        const params = new URLSearchParams(window.location.search);
        const type = params.get('type');

        if (type === '1') {
            this.breadcrumb = 'app.breadcrumbs.newTournament';
        } else if (type === '2') {
            this.breadcrumb = 'app.breadcrumbs.newQualifier';
        }

    } else if (/\/competitions\/\d+/.test(this.currentUrl)) { // Ruta dinámica competitions + id
        this.breadcrumb = 'app.breadcrumbs.competitionName';

    } else if (/\/practices\/sessions\/\d+/.test(this.currentUrl)) {
        this.breadcrumb = 'app.breadcrumbs.sessionName';

    } else if (/\/practices\/sessions\/edit\/\d+/.test(this.currentUrl)) {
        this.breadcrumb = 'app.breadcrumbs.practicesEditSession';

    } else {
        this.breadcrumb = ''; 
    }
  }

  showBackButton(): boolean {
    const simpleRoutes = ['/players', '/competitions', '/stats'];
    const isSimpleRoute = simpleRoutes.includes(this.currentUrl);
    const isNestedRoute = simpleRoutes.some(route => this.currentUrl.startsWith(route + '/'));

    return !isSimpleRoute || isNestedRoute;
  }

  setExtraBreadcrumb(): void {
    // Mostrar el breadcrumb adicional si la ruta actual es `/players` o `/stats`
    this.showExtraBreadcrumb = this.currentUrl === '/players' || this.currentUrl === '/stats';
  }

  back(): void {
    this.location.back()
  }

  capitalizeParams(): void {
    if (this.params) {
      Object.keys(this.params).forEach((key) => {
        const value = this.params![key];
        this.params![key] = this.titleCasePipe.transform(value);
      });
    }
  }
}
