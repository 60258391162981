// navigation.service.ts
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _previousUrl: string = '';
  private _currentUrl: string = '';
  private _initialUrl: string | null = null; // Nueva propiedad para almacenar la URL de origen

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._previousUrl = this._currentUrl;
        this._currentUrl = event.urlAfterRedirects;

        if (this._currentUrl === '/players' || this._currentUrl === '/stats') {
          this.setInitialUrl(this._currentUrl);
          console.log('url inicial',this.currentUrl);
          
        }

      }
    });
  }

  get previousUrl(): string {
    return this._previousUrl;
  }

  get currentUrl(): string {
    return this._currentUrl;
  }

  // Método para establecer la URL de origen inicial si no ha sido establecida
  setInitialUrl(url: string): void {
    if (!this._initialUrl || this._initialUrl !== url) {
      this._initialUrl = url;
    }
  }


  // Método para obtener la URL de origen inicial
  getInitialUrl(): string | null {
    return this._initialUrl;
  }

  // Método para limpiar la URL de origen inicial después de usarla
  clearInitialUrl(): void {
    this._initialUrl = null;
  }
}
