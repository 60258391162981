import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { MixpanelBaseService } from '../mixpanel-base.service';
import { CompetitionInterface } from '../interface/mixpanel.interface'
import { Exercise } from '../../models/exercise';
import { Competition } from '../../models/competition';
import { Round } from '../../models/round';
import { TeeTime } from '../../models/tee-time';
import { Tee } from '../../models/tee';


@Injectable({
  providedIn: 'root',
})
export class MixpanelCompetitionService extends MixpanelBaseService implements CompetitionInterface {
    constructor() {
        super();
    }

    clickNewCompetition(): void{
        mixpanel.track('Click New Competition', {});
    }

    newCompetition(competition: Competition, start_date: string, course: string, tee: string, scorecard: string): void{
        let type = '';
        if(competition.type == 1){
            type = 'TOURNAMENT';
        }
        else if(competition.type == 2){
            type = 'QUALIFIER';
        }

        let livescoring = competition.livescoring ? 'TRUE' : 'FALSE'


        mixpanel.track('New Competition', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Competition type': type,
            'Competition type id': competition.type,
            'Competition team id': competition.team_id,
            'Competition team name': competition.team_name,
            'Competition season id': competition.season_id,
            'Competition season name': competition.season_name,
            'Competition start date': start_date,
            'Competition livescoring': livescoring, 
            'Competition golf course':course,
            'Competition tee': tee,
            'Competition scorecard': scorecard,
        });
    }

    editCompetition(competition: Competition, start_date: string, course: string, tee: string, scorecard: string): void{
        let type = '';
        switch(competition.type){
            case 1: type = 'TOURNAMENT'; break;
            case 2: type = 'QUALIFIER'; break;
        }

        let livescoring = competition.livescoring ? 'TRUE' : 'FALSE'


        mixpanel.track('Edit Competition', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Competition type': type,
            'Competition type id': competition.type,
            'Competition team id': competition.team_id,
            'Competition team name': competition.team_name,
            'Competition season id': competition.season_id,
            'Competition season name': competition.season_name,
            'Competition start date': start_date,
            'Competition livescoring': livescoring, 
            'Competition golf course':course,
            'Competition tee': tee,
            'Competition scorecard': scorecard,
        });
    }

    addPlayers(competition: Competition, players: any[any]){
        mixpanel.track('Add Competition Players', {
            'Competition name': competition.name,
            'Competition Id': competition.id,
            'Players': players,
        });

    }

    newRound(competition: Competition, holes: number, date: string, round: number): void{
        mixpanel.track('New Competition Round', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Competition round holes': holes,
            'Competition round date': date,
            'Competition round number': round,
        });
    }

    editRound(competition: Competition, round: Round): void{
        mixpanel.track('Edit Competition Round', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Competition round holes': round.holes,
            'Competition round date': round.date,
            'Competition round number': round.round,
        });
    }

    deleteRound(competition: Competition, round: Round): void{
        mixpanel.track('Delete Competition Round', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Competition round holes': round.holes,
            'Competition round date': round.date,
            'Competition round number': round.round,
        });
    }

    newTeeTime(competition: Competition, teeTime: TeeTime, hasChangedCourse: boolean): void{
        mixpanel.track('New Competition Tee Time', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Tee time course': teeTime.course.course_name,
            'Tee time tee': teeTime.course.tee_name,
            'Tee time scorecard': teeTime.course.scorecard_name,
            'Course changed': hasChangedCourse,
            'Tee time starting hole': teeTime.hole,
            'Tee time hour': teeTime.hour,
            'Tee time round': teeTime.round,
        });
    }

    ediTeeTime(competition: Competition, teeTime: TeeTime, hasChangedCourse: boolean): void{
        mixpanel.track('Edit Competition Tee Time', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Tee time course': teeTime.course.course_name,
            'Tee time tee': teeTime.course.tee_name,
            'Tee time scorecard': teeTime.course.scorecard_name,
            'Course changed': hasChangedCourse,
            'Tee time starting hole': teeTime.hole,
            'Tee time hour': teeTime.hour,
            'Tee time round': teeTime.round,
            'Tee time id': teeTime.id,
        });
    }

    deleteTeeTime(competition: Competition, teeTime: TeeTime): void{
        mixpanel.track('Delete Competition Tee Time', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Tee time course': teeTime.course.course_name,
            'Tee time tee': teeTime.course.tee_name,
            'Tee time scorecard': teeTime.course.scorecard_name,
            'Tee time starting hole': teeTime.hole,
            'Tee time hour': teeTime.hour,
            'Tee time round': teeTime.round,
            'Tee time id': teeTime.id
        });
    }

    clickCompetitionTab(tabName: string): void{
        mixpanel.track('Click Competition Tab', {
            'Tab name': tabName,
        });
    }
    
    putCompetitionHole(competition: Competition, hole: number, gross: number, par: number): void{
        mixpanel.track('Put Competition Hole', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Hole': hole,
            'Gross': gross,
            'Par': par
        })
    }


}
