import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  
  
  private scrollSubject = new Subject<number>();

  getScrollPosition() {
    return this.scrollSubject.asObservable();
  }

  setScrollPosition(offset: number) {
    this.scrollSubject.next(offset);
  }

  // Sticky button visibility management
  private stickyButtonVisibleSubject = new BehaviorSubject<boolean>(false);
  stickyButtonVisible$ = this.stickyButtonVisibleSubject.asObservable();

  setStickyButtonVisible(visible: boolean) {
    this.stickyButtonVisibleSubject.next(visible);
  }
}
