import { Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Core } from '../../core/core';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  /* Usuario conectado */
  user: User = new User();
  isDemo = environment.demo

  /* Atributos de la vista actual */
  title!: string;
  filters!: boolean;
  back!: string;

  
  activeMenuItem = '';

  constructor(
    private route: Router,
    private core: Core,
    private authService: AuthenticationService,
    private location: Location,

  ) { 
    this.getData();

  }


  ngOnInit(): void { 
    console.log('Check de pipeline')
    this.loadActiveMenuItem();
  }


  /**
   * Cargamos la información que hay actualmente en los selectores de la cabecera.
   */
  getData() {    
    this.getUser()
    this.getHeader()
  }

  getHeader(){
    this.core.getHeader().subscribe( res =>{
      this.back = res.backButton
      this.title = res.title;
      this.filters = res.filters;
    });
  }

  getUser(){
    this.core.getUser().subscribe( res => {
      this.user = res;
    });
  }

  /**
   * Navegamos atrás.
   */
  backClicked() {
    this.location.back();
  }

  goToChat(){
    this.route.navigate(['/chat'])
  }

  goToConfiguration(){
    this.route.navigate(['/configuration'])
  }

  /**
   * Desconectamos de la sesión actual.
   */
  logout(){
    this.core.logOut();
  }

  loadActiveMenuItem(){
    this.core.getMenuActive().subscribe(res=>{
      this.activeMenuItem = res
    })
  }



}
