<div [ngClass]="{ 
    'navigation': !isDashboard && !hasTabs, 
    'navigation--dashboard': isDashboard, 
    'navigation--sm-gap': hasTabs 
}">
    <div *ngIf="isDashboard; else defaultNavigation" class="navigation__last">
        <span class="navigation__last__dashboard">Hi!</span>
        <img src="assets/icons/so-so.svg"/>
    </div>

    <ng-template #defaultNavigation>
        <div *ngIf="showBackButton()" class="navigation__last" (click)="back()">
            <img src="assets/icons/arrow-narrow-left.svg"/>
            <span class="navigation__last__text" [translate]="breadcrumbBack" [translateParams]="backParams"></span>
        </div>
    </ng-template>


    <div class="navigation--row">
        <div class="navigation__container">
            <span 
            *ngIf="showExtraBreadcrumb" 
            class="navigation__title--small" 
            [translate]="extraBreadcrumb">
            </span>
            <span 
            class="navigation__title" 
            [translate]="breadcrumb" 
            [translateParams]="params">
            </span>
        </div>
        
        <div class="navigation__title__gap">
            <div *ngIf="showInput" class="input_wrapper">
                <input type="text"  class="input_header" 
                [(ngModel)]="searchText" 
                (ngModelChange)="onSearchChange(searchText)"  
                [attr.placeholder]="placeholderText">
                <img *ngIf="showInput" class="navigation__title__img" src="../../../../../assets/icons/search.svg"/>
            </div>
            <span *ngIf="showSpan" [translate]="buttonText" class="button button__primary cursor-pointer" (click)="onButtonClick()" >
                {{ buttonText }}
            </span>
        </div>
    </div>
    
    <span *ngIf="!hasTabs" class="navigation__separator"></span>
</div>
