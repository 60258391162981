import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  
  
  private scrollSubject = new Subject<number>();

  getScrollPosition() {
    return this.scrollSubject.asObservable();
  }

  setScrollPosition(offset: number) {
    this.scrollSubject.next(offset);
  }

  // Sticky button visibility management
  private stickyButtonVisibleSubject = new BehaviorSubject<boolean>(false);
  stickyButtonVisible$ = this.stickyButtonVisibleSubject.asObservable();

  setStickyButtonVisible(visible: boolean) {
    this.stickyButtonVisibleSubject.next(visible);
  }

   // Window Width Management
   private widthSubject = new BehaviorSubject<number>(window.innerWidth);

   getWindowWidth(): Observable<number> {
     return this.widthSubject.asObservable();
   }
 
   constructor() {
     // Listen for resize events
     fromEvent(window, 'resize')
       .pipe(
         debounceTime(50), // Avoid emitting too frequently
         map(() => window.innerWidth), // Only track width
         startWith(window.innerWidth) // Emit the initial width
       )
       .subscribe((width) => {
         this.widthSubject.next(width);
       });
   }
}
