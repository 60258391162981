import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './public/pages/login/login.component';
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { ModuleGuard } from './shared/guards/module/module.guard';
import { RoleGuard } from './shared/guards/role/role.guard';
import { CreateSessionComponent } from './features/practices/components/sessions/create-session/create-session.component';
import { EditSessionComponent } from './features/practices/components/sessions/edit-session/edit-session.component';

// Usamos el canLoad por si vamos a cargar el compontent/modulo (por modules)
// Usamos el canActivate si queremos comprobar si hay login en la web

const routes: Routes = [
  // public - no auth
  {   
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
    data:[]
  },
  { path: 'login', 
    pathMatch: 'full',
    component: LoginComponent,
    data:[]
  },
  // need auth
  { 
    path: 'dashboard',
    loadChildren: () => import('./../app/features/home/home.module').then(m => m.HomeModule),
    data:[],
    canActivate: [AuthGuard],
  },
  { 
    path: 'players',
    data:[],
    children: [
      {
        path: ':id',
        loadChildren: () => import('./../app/features/players/pages/detail/detail.module').then(m => m.PlayerDetailModule),
        data: {}, //module:['player']
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./../app/features/players/players.module').then(m => m.PlayersModule),
        data: {}, //module:['player']
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
      }
    ]
  },
  // { 
  //   path: 'practices',
  //   loadChildren: () => import('./../app/features/practices/practices.module').then(m => m.PracticesModule),
  //   data: {module:['general_practice', 'work_outs']},
  //   canLoad: [ModuleGuard],
  //   canActivate: [AuthGuard],
  // },
  //----
  { 
    path: 'practices',
    data: {module:['general_practice']},
    canLoad: [ModuleGuard],
    canActivate: [AuthGuard],
    children: [
      {
        path:'',
        loadChildren: () => import('./../app/features/practices/practices.module').then(m => m.PracticesModule),
      },
    ]
  },
  { 
    path: 'workouts',
    data: {module:['work_outs']},
    canLoad: [ModuleGuard],
    canActivate: [AuthGuard],
    children: [
      {
        path: ':id',
        data: {module:['work_outs']},
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/workouts/components/session-all-info/session-all-info.module').then(m => m.SessionAllInfoModule) 
      },
      {
        path:'',
        loadChildren: () => import('./../app/features/workouts/workouts.module').then(m => m.WorkoutsModule),
      }
    ]
  },
  //----
  { 
    path: 'stats',
    children: [
      {
        path: ':playerId/stat/:statId',
        loadChildren: () => import('./../app/features/stats/pages/competition-player-stats/competition-player-stats.module').then(m => m.CompetitionPlayerStatsModule),
        data: {module:['stats']},
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
      },
      {
        path: ':id',
        loadChildren: () => import('./../app/features/stats/pages/personal/personal.module').then(m => m.PersonalStatsModule),
        data: {module:['stats']},
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./../app/features/stats/stats.module').then(m => m.StatsModule),
        data: {module:['stats']},
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
      }
    ],
  },
  { 
    path: 'trips',
    loadChildren: () => import('./../app/features/trips/trips.module').then(m => m.TripsModule),
    data: {module:['trips']},
    canLoad: [ModuleGuard],
    canActivate: [AuthGuard],
  },
  { 
    path: 'chat',
    loadChildren: () => import('./../app/features/chat/chat.module').then(m => m.ChatModule),
    data: {module:['communications']},
    canLoad: [ModuleGuard],
    canActivate: [AuthGuard],
  },
  { 
    path: 'recruitment',
    data:[],
    children: [
      {
        path: ':id',
        loadChildren: () => import('./../app/features/recruitment/pages/detail/detail.module').then(m => m.RecruitmentDetailModule),
        data: { module:['recruitment'] },
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./../app/features/recruitment/recruitment.module').then(m => m.RecruitmentModule),
        data: { module:['recruitment'] },
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
      }
    ]
  },
  { 
    path: 'equipment',
    loadChildren: () => import('./features/equipment/equipment.module').then(m => m.EquipmentModule),
    data: {module:['warehouse']},
    canLoad: [ModuleGuard],
    canActivate: [AuthGuard],
  },
  { 
    path: 'configuration',
    loadChildren: () => import('./../app/features/configuration/configuration.module').then(m => m.ConfigurationModule),
    data: {role:[1, 2]},
    canLoad: [ModuleGuard],
    canActivate: [RoleGuard, AuthGuard],
  },
  { 
    path: 'competitions',
    children: [
      {
        path: 'create',
        loadChildren: () => import('./../app/features/competitions/pages/create/create.module').then(m => m.CompetitionCreateModule),
        data: {module:['competitions']},
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
      },
      {
        path: ':id',
        loadChildren: () => import('./../app/features/competitions/pages/detail/detail.module').then(m => m.CompetitionDetailModule),
        data: {module:['competitions']},
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./../app/features/competitions/competitions.module').then(m => m.CompetitionsModule),
        data: {module:['competitions']},
        canLoad: [ModuleGuard],
        canActivate: [AuthGuard],
      }
    ],
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule],
  providers: [AuthGuard, ModuleGuard]
})
export class AppRoutingModule { }
