import { Injectable } from '@angular/core';
import { User } from 'src/app/shared/models/user';
import mixpanel from 'mixpanel-browser';
import { MixpanelBaseService } from '../mixpanel-base.service';
import { SignUpTrackingInterface } from '../interface/mixpanel.interface';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class MixpanelSignUpService extends MixpanelBaseService implements SignUpTrackingInterface {
  constructor() {
    super();
  }

  signUp(user: User): void {
    this.initMixpanel();

    const distinctId = user.email;
    
    mixpanel.identify(distinctId);

    let newRole = '';
    switch(user.role){
      case 1: newRole = 'ADMIN'; break;
      case 2: newRole = 'COACH'; break;
      case 3: newRole = 'PLAYER'; break;
      default: break;
    }

    let newDate = format(new Date(user.creation_date! * 1000), 'yyyy.MM.dd hh:ii:ss')
    let entidad = user.entity_fav;

    mixpanel.people.set({
      $token: this.token,
      $name: user.name + ' ' + user.surname,
      $email: user.email,
      Role: newRole,
      'Fecha creacion': newDate,
      'Entidad':entidad.name,
      'Id Entidad':entidad.id,
      'Freelance': user.freelance
    });

    // Añadir track de sing in
    mixpanel.track('Sign in', {
      'User name': user.name + ' ' + user.surname,
      'User id': user.id,
    });

  }
}
