import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@/app/shared/services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header-page',
  templateUrl: './header-page.component.html',
  styleUrls: ['./header-page.component.scss'],
  providers: [TitleCasePipe]
})
export class HeaderPageComponent implements OnInit {
  breadcrumb: string = '';
  extraBreadcrumb: string = 'app.breadcrumbs.teamExtra';
  entityName: string | undefined;
  user: string | undefined;
  breadcrumbBack: string = '';
  backParams?: { [key: string]: string };
  @Input() params?: { [key: string]: any } = {};
  isDashboard: boolean = false;

  // Definir `previousUrl` y `currentUrl` como propiedades del componente
  previousUrl: string = '';
  currentUrl: string = '';
  showInput: boolean = false;

  showExtraBreadcrumb: boolean = false;
  //Para que al clicar en el botón de la cabecera se ejecute la función del botón del componente hijo
  @Output() open = new EventEmitter<void>();
  buttonText: string = '';

  //Para saber la tab seleccionada y poner el texto del botón
  @Input() selectedTab!: number;
  @Input() userRole: number | undefined;
  placeholderText: string = '';
  private langChangeSubscription: Subscription | undefined;

  @Output() searchChanged = new EventEmitter<string>();
  searchText: any;
  tab: any;


  constructor(
    private navigationService: NavigationService, 
    private titleCasePipe: TitleCasePipe,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    // Asignar valores a `previousUrl` y `currentUrl` en el momento de inicialización
    this.previousUrl = this.navigationService.previousUrl;
    this.currentUrl = this.navigationService.currentUrl; 
    
    this.updateNavigationValues();
    this.updatePlaceholderText();
    this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
      this.updatePlaceholderText();
    });

    if(this.currentUrl == '/competitions'){   
      this.changeTab()
    }


    // Mostrar el input solo en las rutas /practices/sessions y /practices/exercises
    this.showInput = ['/practices/sessions', '/practices/exercises', '/competitions', '/configuration'].includes(this.currentUrl);


    
    if (this.currentUrl === '/practices/sessions') {
      this.buttonText = 'New Session'
    } else if (this.currentUrl === '/practices/exercises') {
      this.buttonText = "New Exercise"
    } 
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.user = this.params?.player || '';  
    this.tab = this.params?.tab
    if (this.currentUrl === '/configuration') {     
      if (this.params?.tab === 'users') {
        this.buttonText = 'app.configuration.newUser.btnNewUser';
      } else if (this.params?.tab === 'institutions') {
        this.buttonText = 'app.configuration.newInstitution.btnNewInstitution';
      } else if (this.params?.tab === 'teams') {
        this.buttonText = 'app.configuration.newTeam.btnNewTeam';
      } else if (this.params?.tab === 'seasons') {
        this.buttonText = 'app.configuration.newSeason.btnNewSeason';
      } else if (this.params?.tab === 'golf courses') {
        this.buttonText = 'app.configuration.newCourse.btnNewCourse';
      }
    }

    this.updateNavigationValues();
    //this.capitalizeParams()
    this.changeTab()
  }

  ngOnDestroy(): void {
    // Anular la suscripción para evitar pérdidas de memoria
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  private updateNavigationValues(): void {
    this.isDashboard = this.currentUrl === '/dashboard';
    this.setBreadcrumb();
  }

  setBreadcrumb(): void {
    const routeMap: any = {
        '/dashboard': 'app.breadcrumbs.dashboard',
        '/players': 'app.breadcrumbs.team',
        '/stats': 'app.breadcrumbs.teamStats',
        '/team/player': 'app.breadcrumbs.teamPlayer',
        '/competitions': 'app.breadcrumbs.competitions',
        '/trips': 'app.breadcrumbs.trips',
        '/configurations': 'app.breadcrumbs.configuration',
        '/competitions/create': 'app.breadcrumbs.createCompetition',
        '/practices/sessions': 'app.breadcrumbs.sessions',
        '/practices/exercises': 'app.breadcrumbs.exercises',
        '/configuration': 'app.breadcrumbs.configuration'
    };

    this.setExtraBreadcrumb()

    if (routeMap[this.currentUrl]) {
        this.breadcrumb = routeMap[this.currentUrl];

    } else if (/\/players\/\d+/.test(this.currentUrl)) { // Ruta dinámica: player + id
        this.breadcrumb = 'app.breadcrumbs.playerProfile';
        this.breadcrumbBack = 'app.breadcrumbs.teamPlayerBack';

    } else if (/\/stats\/\d+\/stat\/\d+/.test(this.currentUrl)) { // Ruta dinámica: stats + id + stat +id
        this.breadcrumb = 'app.breadcrumbs.teamPlayersPlayerStat';
        // Comprobación de previousUrl para asignar los valores específicos
        if (/\/competitions\/\d+/.test(this.previousUrl)) {
          this.breadcrumbBack = 'app.breadcrumbs.competitionNameBack';
          this.backParams = { competition: this.params?.competition || '' };
      } else {
          this.breadcrumbBack = 'app.breadcrumbs.teamPlayersPlayerStatBack';
          this.backParams = { player: this.user || '' };
      }

    } else if (/\/stats\/\d+/.test(this.currentUrl)) { // Ruta dinámica: stats + id
        const initialUrl = this.navigationService.getInitialUrl();
        
        this.breadcrumb = 'app.breadcrumbs.playerStatsCompetition';
        this.breadcrumbBack = initialUrl === '/players'
        ? 'app.breadcrumbs.playerProfileBack'
        : 'app.breadcrumbs.alternatePlayerProfileBack';
        
        this.backParams = { player: this.user || '' };
        
    } else if (/\/competitions\/create/.test(this.currentUrl)) { // Ruta dinámica competitions/create
      this.breadcrumbBack = 'app.breadcrumbs.competitionBack';
      this.activatedRoute.queryParams.subscribe(params => {
        const type = params['type']; // Aquí accedes al parámetro 'type'
        
        if (type === '1') {
          this.breadcrumb = 'app.breadcrumbs.newTournament';
        } else if (type === '2') {
          this.breadcrumb = 'app.breadcrumbs.newQualifier';
        }
      });

    } else if (/\/competitions\/\d+/.test(this.currentUrl)) { // Ruta dinámica competitions + id
        this.breadcrumb = 'app.breadcrumbs.competitionName';
        this.breadcrumbBack = 'app.breadcrumbs.competitionBack';

    } else if (/\/practices\/sessions\/\d+/.test(this.currentUrl)) { // Ruta dinámica practices/sessions + id
        this.breadcrumb = 'app.breadcrumbs.sessionName';
        this.breadcrumbBack = 'app.breadcrumbs.sessionBack'

    } else if (/\/practices\/sessions\/edit\/\d+/.test(this.currentUrl)) { // Ruta dinámica practices/sessions/edit + id
        this.breadcrumb = 'app.breadcrumbs.practicesEditSession';

    } else if (/\/practices\/sessions\/create/.test(this.currentUrl)) { // Ruta dinámica practices/sessions/edit + id
      this.breadcrumb = 'app.breadcrumbs.practicesNewSession';
      this.breadcrumbBack = 'app.breadcrumbs.sessionBack'

    } else {
        this.breadcrumb = ''; 
    }
  }

  showBackButton(): boolean {
    const simpleRoutes = ['/players', '/competitions', '/stats', '/practices/sessions', '/practices/exercises', '/configuration'];
    const isSimpleRoute = simpleRoutes.includes(this.currentUrl);
    const isNestedRoute = simpleRoutes.some(route => this.currentUrl.startsWith(route + '/'));

    return !isSimpleRoute || isNestedRoute;
  }

  setExtraBreadcrumb(): void {
    // Mostrar el breadcrumb adicional si la ruta actual es `/players` o `/stats`
    this.showExtraBreadcrumb = this.currentUrl === '/players' || this.currentUrl === '/stats' ||  this.currentUrl === '/practices/sessions' ||  this.currentUrl === '/practices/exercises';
    this.currentUrl.includes('/practices') ? this.extraBreadcrumb = 'app.breadcrumbs.practicesExtra' : 'app.breadcrumbs.teamExtra'
  }

  onButtonClick() {
    this.open.emit();  // Emitimos el evento cuando se hace clic en el botón
  }

  changeTab(): string { 
    if (this.currentUrl == '/competitions' ) {
      return this.buttonText = this.selectedTab === 1 ? 'app.competitions.tournament' : 'app.competitions.qualifier';
    }
    return '';


  }

  // Getter para controlar si se debe mostrar el span
  get showSpan(): boolean {
    const currentUrl = this.navigationService.currentUrl;
  
    // Mostrar en `competitions` solo si `userRole` cumple con la condición
    if (currentUrl === '/competitions' || currentUrl === '/practices/sessions')  {
      
      return this.userRole !== 3;
    }
    if (currentUrl === '/configuration' && this.tab === 'all users') {
      return false;  // No mostrar el botón si estamos en 'configuration' y 'tab' es 'all users'
    }
  
    // Mostrar en `practices/sessions` y `practices/exercises` sin condiciones adicionales
    return ['/practices/sessions', '/practices/exercises' , '/configuration'].includes(currentUrl);
  }

  get hasTabs(): boolean {
    return this.params?.hasTabs ?? false;
  }

  onSearchChange(value: string) {
    this.searchChanged.emit(value);
  }

  back(): void {
    this.location.back()
  }

  capitalizeParams(): void {
    if (this.params) {
      Object.keys(this.params).forEach((key) => {
        const value = this.params![key];
        this.params![key] = this.titleCasePipe.transform(value);
      });
    }
  }

  updatePlaceholderText(): void {
    this.translateService.get('app.practices.placeHolder').subscribe((text: string) => {
      this.placeholderText = text;
    });
  }
}
