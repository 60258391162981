import { Inject, Injectable } from "@angular/core";
import { CompetitionInterface, CompetitionTrackingToken, GeneralEventsTrackingInterface, GeneralEventsTrackingToken, GeneralPracticeInterface, GeneralPracticeTrackingToken, MixpanelInterface, SignUpTrackingInterface, SignUpTrackingToken, StatsTrackingInterface, StatsTrackingToken } from "./interface/mixpanel.interface";
import { User } from "../models/user";
import { Exercise } from "../models/exercise";
import { Competition } from "../models/competition";
import { Round } from "../models/round";
import { TeeTime } from "../models/tee-time";


@Injectable()
export class MixpanelService implements MixpanelInterface{
  constructor(
    @Inject(GeneralEventsTrackingToken) private generalService: GeneralEventsTrackingInterface,
    @Inject(SignUpTrackingToken) private signUpService: SignUpTrackingInterface,
    @Inject(StatsTrackingToken) private statsService: StatsTrackingInterface,
    @Inject(GeneralPracticeTrackingToken) private generalPracticeService: GeneralPracticeInterface,
    @Inject(CompetitionTrackingToken) private competitionService: CompetitionInterface,
  ){}

  /** Sign Up Service */
  signUp(user: User): void {
    this.signUpService.signUp(user);
  }

  /** General Service */
  trackPageName(pageName: string, currentUrl: string): void {
    this.generalService.trackPageName(pageName, currentUrl);
  }

  trackTooltip(tooltipName: string, pageName: string, modalName: string): void{
    this.generalService.trackTooltip(tooltipName, pageName, modalName);
  }

  trackChangeDistance(distance: string){
    this.generalService.trackChangeDistance(distance)
  }


  /** Stats Service */
  trackStat(name: string, id: number, typeName: string, typeId:number, categoryName: string, categoryId: number, startDate: number, endDate: number): void {
    this.statsService.trackStat(name, id, typeName, typeId, categoryName, categoryId, startDate, endDate)
  }

  trackPlayerCompetitionStat(id: number, competitionId: number, competitionName: string, typeId:number, statId: number): void{
    this.statsService.trackPlayerCompetitionStat(id, competitionId, competitionName, typeId, statId);
  }

  trackPlayerCompetitionStatHole(id: number): void{
    this.statsService.trackPlayerCompetitionStatHole(id)
  }

  trackPlayerCompetitionStatChangeRound(id:number, round: number): void{
    this.statsService.trackPlayerCompetitionStatChangeRound(id, round)
  }

  trackPlayerCompetitionStatChangeCompetition(newId: number, newName: string, originalId: number, originalName: string, playerId: number, type: number): void{
    this.statsService.trackPlayerCompetitionStatChangeCompetition(newId, newName, originalId, originalName, playerId, type)
  }

  trackPlayerCompetitionStatChangePlayer(playerId: number, playerName: string, originalPlayerId: number, originalPlayerName: string, statId: number, statName: string, type: number): void{
    this.statsService.trackPlayerCompetitionStatChangePlayer(playerId, playerName, originalPlayerId, originalPlayerName, statId, statName, type)
  }

  trackPlayerCompetitionStatActivateRound(id: number, round: number, playerId: number, active: boolean, type: number): void{
    this.statsService.trackPlayerCompetitionStatActivateRound(id, round, playerId, active, type)
  }

  trackPlayerCompetitionStatClickCard(id: number, round: number, playerId: number, areaId: number, type: number): void{
    this.statsService.trackPlayerCompetitionStatClickCard(id, round, playerId, areaId, type)
  }

  trackPlayerCompetitionStatShotByShot(id: number, round: number, hole: number, playerId: number, type: number): void{
    this.statsService.trackPlayerCompetitionStatShotByShot(id, round, hole, playerId, type)
  }

  /** General Practice Service */
  newExercise(exercise: Exercise): void{
    this.generalPracticeService.newExercise(exercise)
  }
  editExercise(exercise: Exercise): void{
    this.generalPracticeService.editExercise(exercise)
  }
  deleteExercise(exercise: Exercise): void{
    this.generalPracticeService.deleteExercise(exercise)
  }
  cloneExercise(exercise: Exercise): void{
    this.generalPracticeService.cloneExercise(exercise)
  }
  clickNewExercise(): void{
    this.generalPracticeService.clickNewExercise();
  }
  clickSessionResults(): void{
    this.generalPracticeService.clickSessionResults();
  }


  /** Competitions */
  clickNewCompetition(): void{
    this.competitionService.clickNewCompetition();
  }

  newCompetition(competition: Competition, start_date: string, course: string, tee: string, scorecard: string): void{
    this.competitionService.newCompetition(competition, start_date, course, tee, scorecard);
  }
  
  editCompetition(competition: Competition, start_date: string, course: string, tee: string, scorecard: string): void{
    this.competitionService.editCompetition(competition, start_date, course, tee, scorecard);
  }

  newRound(competition: Competition, holes: number, date: string, round: number){
    this.competitionService.newRound(competition, holes, date, round);
  }
  
  editRound(competition: Competition, round: Round){
    this.competitionService.editRound(competition, round);
  }
  
  deleteRound(competition: Competition, round: Round){
    this.competitionService.deleteRound(competition, round);
  }
  
  newTeeTime(competition: Competition, teeTime: TeeTime, hasChangedCourse: boolean): void{
    this.competitionService.newTeeTime(competition, teeTime, hasChangedCourse)
  }

  ediTeeTime(competition: Competition, teeTime: TeeTime, hasChangedCourse: boolean): void{
    this.competitionService.ediTeeTime(competition, teeTime, hasChangedCourse)
  }

  deleteTeeTime(competition: Competition, teeTime: TeeTime): void{
    this.competitionService.deleteTeeTime(competition, teeTime)
  }
  clickCompetitionTab(tabName: string): void{
    this.competitionService.clickCompetitionTab(tabName)
  }
  
  putCompetitionHole(competition: Competition, hole: number, gross: number, par: number): void{
    this.competitionService.putCompetitionHole(competition, hole, gross, par)
  }

  addPlayers(competition: Competition, players: any[any]): void{
    this.competitionService.addPlayers(competition, players);
  }

}