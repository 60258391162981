import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { MixpanelBaseService } from '../mixpanel-base.service';
import { StatsTrackingInterface } from '../interface/mixpanel.interface'
import { format } from 'date-fns';


@Injectable({
  providedIn: 'root'
})
export class MixpanelStatsService extends MixpanelBaseService implements StatsTrackingInterface {
  constructor() {
    super();
  }

  trackStat(name: string, id: number, typeName: string, typeId:number, categoryName: string, categoryId: number, startDate: number, endDate: number){
    this.initMixpanel();

    if(endDate == 0 || startDate == 0){return}

    let newStartDate = format(new Date(startDate * 1000), 'yyyy.MM.dd')
    let newEndDate = format(new Date(endDate * 1000), 'yyyy.MM.dd')

    mixpanel.track('View Stat', {
      'Stat name': name.toUpperCase(),
      'Stat id': id,
      'Stat type': typeName, 
      'Stat type id': typeId, 
      'Stat category': categoryName,
      'Stat category id': categoryId,

      'Filter start date': newStartDate,
      'Filter end date': newEndDate,
    });
  }

  trackPlayerCompetitionStat(id: number, competitionId: number, competitionName: string, typeId:number, statId: number){

    let typeName = '';
    switch(typeId){
      case 1: typeName = 'TOURNAMENT'; break;
      case 2: typeName = 'QUALIFIER'; break;
      case 3: typeName = 'PRACTICE STAT'; break;
    }

    mixpanel.track('Player Competition Stat', {
      'Player Id': id,
      'Competition id': competitionId,
      'Competition name': competitionName,
      'Type': typeName, 
      'Type id': typeId, 
      'Stat id': statId
    });
  }

  trackPlayerCompetitionStatHole(id: number){
    mixpanel.track('Player Competition Stat Hole', {
      'Id': id
    })
  }

  trackPlayerCompetitionStatChangeRound(id: number, round: number){
    mixpanel.track('Player Competition Stat Change Round', {
      'Id': id,
      'Round': round
    })

  }

  trackPlayerCompetitionStatChangeCompetition(newId: number, newName: string, originalId: number, originalName: string, playerId: number, type: number){
    let typeName = '';
    switch(type){
      case 1: typeName = 'TOURNAMENT'; break;
      case 2: typeName = 'QUALIFIER'; break;
      case 3: typeName = 'PRACTICE STAT'; break;
    }
    mixpanel.track('Player Competition Stat Change Competition', {
      'Stat Id': newId,
      'Stat Name': newName,
      'Original Stat id': originalId,
      'Original Stat name': originalName,
      'Player id': playerId,
      'Type': type, 
      'Type name': typeName
    })
  }


  trackPlayerCompetitionStatChangePlayer(playerId: number, playerName: string, originalPlayerId: number, originalPlayerName: string, statId: number, statName: string, type: number): void{
    let typeName = '';
    switch(type){
      case 1: typeName = 'TOURNAMENT'; break;
      case 2: typeName = 'QUALIFIER'; break;
      case 3: typeName = 'PRACTICE STAT'; break;
    }

    mixpanel.track('Player Competition Stat Change Player', {
      'Player id': playerId,
      'Player Name': playerName,
      'Original Player id': originalPlayerId,
      'Original Player name': originalPlayerName,
      'Stat id': statId,
      'Stat name': statName,
      'Type': type, 
      'Type name': typeName
    })
  }


  trackPlayerCompetitionStatActivateRound(id: number, round: number, playerId: number, active: boolean, type: number){
    let typeName = '';
    switch(type){
      case 1: typeName = 'TOURNAMENT'; break;
      case 2: typeName = 'QUALIFIER'; break;
      case 3: typeName = 'PRACTICE STAT'; break;
    }

    mixpanel.track('Player Competition Stat Activate Round', {
      'Player id': playerId,
      'Stat id': id,
      'Round': round,
      'Active': active,
      'Type': type, 
      'Type name': typeName
    })
  }


  trackPlayerCompetitionStatClickCard(id: number, round: number, playerId: number, areaId: number, type: number){
    let typeName = '';
    switch(type){
      case 1: typeName = 'TOURNAMENT'; break;
      case 2: typeName = 'QUALIFIER'; break;
      case 3: typeName = 'PRACTICE STAT'; break;
    }

    let areaName = '';
    switch(areaId){
      case 1: areaName = 'OFF THE TEE'; break;
      case 2: areaName = 'TO THE GREEN'; break;
      case 3: areaName = 'AROUND THE GREEN'; break;
      case 4: areaName = 'ON THE GREEN'; break;
    }

    mixpanel.track('Player Competition Stat Click Card', {
      'Player id': playerId,
      'Stat id': id,
      'Round': round,
      'Area': areaId,
      'Area name': areaName,
      'Type': type, 
      'Type name': typeName
    })
  }

  trackPlayerCompetitionStatShotByShot(id: number, round: number, hole: number, playerId: number, type: number){
    let typeName = '';
    switch(type){
      case 1: typeName = 'TOURNAMENT'; break;
      case 2: typeName = 'QUALIFIER'; break;
      case 3: typeName = 'PRACTICE STAT'; break;
    }

    // console.log('Player Competition Stat Shot by Shot', {
    //   'Player id': playerId,
    //   'Stat id': id,
    //   'Round': round,
    //   'Hole': hole,
    //   'Type': type, 
    //   'Type name': typeName
    // })

    mixpanel.track('Player Competition Stat Shot by Shot', {
      'Player id': playerId,
      'Stat id': id,
      'Round': round,
      'Hole': hole,
      'Type': type, 
      'Type name': typeName
    })
  }
}
