import { Pipe, PipeTransform } from '@angular/core';
import { parse, format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatDateByLang',
  pure: false // Para escuchar cambios de idioma
})
export class FormatDateByLangPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(dateString: string, currentFormat: string = 'MM.dd.yyyy'): any {
    // Obtener el idioma actual
    const currentLang = this.translateService.currentLang;

    // Definir el locale según el idioma
    const locale = currentLang === 'es' ? es : enUS;

    // Determinar el formato que se debe aplicar
    const outputFormat = currentLang === 'es' ? 'dd.MM.yyyy' : 'MM.dd.yyyy';

    // Parsear la fecha de entrada
    const parsedDate = parse(dateString, currentFormat, new Date(), { locale });

    // Formatear la fecha en el formato adecuado según el idioma
    return format(parsedDate, outputFormat, { locale });
  }
}
