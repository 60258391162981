import { Injectable, InjectionToken } from '@angular/core';
import { User } from '../../models/user';
import { Exercise } from '../../models/exercise';
import { Competition } from '../../models/competition';
import { Round } from '../../models/round';
import { TeeTime } from '../../models/tee-time';

// General evetns
export const GeneralEventsTrackingToken = new InjectionToken<GeneralEventsTrackingInterface>('GeneralEventsTrackingInterface');

export interface GeneralEventsTrackingInterface {
  trackPageName(pageName: string, currentUrl: string): void;
  trackTooltip(pageName: string, currentUrl: string, modalName: string): void;
  trackChangeDistance(distance: string): void;
}

// Sign up
export const SignUpTrackingToken = new InjectionToken<SignUpTrackingInterface>('SignUpTrackingInterface');

export interface SignUpTrackingInterface {
  signUp(user: User): void;
}

// Stats
export const StatsTrackingToken = new InjectionToken<StatsTrackingInterface>('StatsTrackingInterface');

export interface StatsTrackingInterface {
  trackStat(name: string, id: number, typeName: string, typeId:number, categoryName: string, categoryId: number, startDate: number, endDate: number): void;
  trackPlayerCompetitionStat(id: number, competitionId: number, competitionName: string, typeId:number, statId: number): void;
  trackPlayerCompetitionStatHole(id: number): void;
  trackPlayerCompetitionStatChangeRound(id: number, round: number): void;
  trackPlayerCompetitionStatChangeCompetition(newId: number, newName: string, originalId: number, originalName: string, playerId: number, type: number): void;
  trackPlayerCompetitionStatChangePlayer(playerId: number, playerName: string, originalPlayerId: number, originalPlayerName: string, statId: number, statName: string, type: number): void;
  trackPlayerCompetitionStatActivateRound(id: number, round: number, playerId: number, active: boolean, type: number): void;
  trackPlayerCompetitionStatClickCard(id: number, round: number, playerId: number, areaId: number, type: number): void;
  trackPlayerCompetitionStatShotByShot(id: number, round: number, hole: number, playerId: number, type: number): void;
}

// General practice
export const GeneralPracticeTrackingToken = new InjectionToken<GeneralPracticeInterface>('GeneralPracticeInterface');

export interface GeneralPracticeInterface {
  newExercise(exercise: Exercise): void;
  editExercise(exercise: Exercise): void;
  deleteExercise(exercise: Exercise): void;
  cloneExercise(exercise: Exercise): void;
  clickNewExercise(): void;
  clickSessionResults(): void;
}


// Competition
export const CompetitionTrackingToken = new InjectionToken<CompetitionInterface>('CompetitionInterface');

export interface CompetitionInterface {
  clickNewCompetition(): void;
  newCompetition(competition: Competition, start_date: string, course: string, tee: string, scorecard: string): void
  editCompetition(competition: Competition, start_date: string, course: string, tee: string, scorecard: string): void
  newRound(competition: Competition, holes: number, date: string, round: number): void
  editRound(competition: Competition, round: Round): void
  deleteRound(competition: Competition, round: Round): void
  newTeeTime(competition: Competition, teeTime: TeeTime, hasChangedCourse: boolean): void
  ediTeeTime(competition: Competition, teeTime: TeeTime, hasChangedCourse: boolean): void
  deleteTeeTime(competition: Competition, teeTime: TeeTime): void
  clickCompetitionTab(tabName: string): void
  putCompetitionHole(competition: Competition, hole: number, gross: number, par: number): void
  addPlayers(competition: Competition, players: any[any]): void;
}


export interface MixpanelInterface
  extends GeneralEventsTrackingInterface,
  SignUpTrackingInterface, StatsTrackingInterface, GeneralPracticeInterface
  {}


